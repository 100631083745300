/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';
import { t } from '@superset-ui/core';
import { detectOS } from 'src/utils/common';

export const getSliceHeaderTooltip = (sliceName: string | undefined, hasSecondLine: boolean = false) => {
  const isMac = detectOS() === 'MacOS';
  const firstLine = sliceName
    ? t('点击可编辑 %s.', sliceName)
    : t('Click to edit chart.');
  const secondLine = t(
    '同时按下 %s 可在新标签中打开.',
    isMac ? t('⌘ 点击') : t('ctrl 点击'),
  );
  return (
    <>
      <div>{firstLine}</div>
      {hasSecondLine && <div>{secondLine}</div>}
    </>
  );
};
